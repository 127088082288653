/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { string, shape } from 'prop-types';
import mbpLogger from 'mbp-logger';
import { useDispatch, useSelector } from 'react-redux';

import ComponentErrorBoundary from '../Common/ComponentErrorBoundary/ComponentErrorBoundary';
import CarouselBuilderSkeleton from '../../GraphqlSkeletonComponents/CarouselBuilderSkeleton';
import SimpleCarouselBuilder from '../Common/SimpleCarouselBuilder';
import useSalesforceBuyItAgainRecsQuery from '../../../../helpers/Personalization/useSalesforceBuyItAgainRecsQuery';
import useClickStreamCustomEventsTracking from '../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import { emitProductImpression, emitCustomTrackingEvent } from '../../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import { getIsAuthenticatedStatus } from '../../../../../state/ducks/Member/ducks/Common/Common-Selectors';

const BuyItAgainRecsSFContainer = ({ blockObj }) => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);

    // show buy again recs only to registered users
    if (!isAuthenticated) return null;

    const {
        products_to_show,
        min_products_to_show,
        buy_again_title,
        css_classes,
        title_margin,
        title_font_color,
        title_font_size,
        title_font_family,
        title_font_style,
        pz_buy_again_campaign_id: campaignFeature = '',
        align_title = 'center',
        padding,
    } = blockObj || {};

    const {
        loading,
        error,
        data,
        variables,
    } = useSalesforceBuyItAgainRecsQuery({
        queryName: 'BuyItAgainRecommendations',
        campaignFeature,
        targeting: {
            pageType: 'home',
        },
        filterByZipCode: true,
    });

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse: data?.buyAgainRecs,
        isFireImpression: data?.buyAgainRecs?.campaign?.campaignResponses?.[0]?.experienceName === 'Control',
        page: { type: 'home' },
    });

    if (loading || variables?.skip) {
        return (
            <CarouselBuilderSkeleton
                nDisplayProducts={products_to_show}
                breakPoints={{ mobile: 601, desktop: 769 }}
            />
        );
    }

    if (error) {
        mbpLogger.logError({
            function: 'BuyItAgainRecsSFContainer.js',
            message: 'Error loading data from Salesforce API',
            appName: process.env.npm_package_name,
            module: 'PersonalizedContent',
            jsError: error,
        });
        return null;
    }

    const renderCarousel = (carouselTitle, products) => (
        <ComponentErrorBoundary function="BuyItAgainRecsSFContainer" module="PersonalizedContent" message="Something went wrong when displaying Buy It Again Recommendations from Salesforce">
            <SimpleCarouselBuilder
                blockData={{
                    productsToShow: products_to_show,
                    title: carouselTitle,
                    css_classes,
                    titleMargin: title_margin,
                    titleFontColor: title_font_color?.color,
                    titleFontSize: title_font_size,
                    titleFontStyle: title_font_style,
                    titleFontFamily: title_font_family,
                    alignTitle: align_title,
                    padding,
                }}
                productClickCallback={trackClickThrough}
                products={products}
                demandType="XS"
            />
        </ComponentErrorBoundary>
    );

    if (data?.buyAgainRecs?.products || data?.buyAgainRecs?.productRecs) {
        const titleSF = data?.buyAgainRecs?.campaign?.campaignResponses?.[0]?.payload?.productRecTitle || data?.buyAgainRecs?.campaign?.campaignResponses?.[0]?.payload?.fallbackProductRecTitle || 'Because you viewed';
        const displayType = data?.buyAgainRecs?.campaign?.campaignResponses?.[0]?.payload?.displayType || '';
        const products = data?.buyAgainRecs?.products;
        const productRecs = data?.buyAgainRecs?.productRecs;

        const trackImpression = () => dispatch(emitCustomTrackingEvent({
            action: 'Impression',
            salesforceResponse: data?.buyAgainRecs,
            page: { type: 'home' },
            eventSubType: 'campaign-tracking',
            displayType,
            min_products_to_show,
        }));

        switch (displayType) {
            case 'BOTH':
                trackImpression();
                if (products?.length >= min_products_to_show) {
                    dispatch(emitProductImpression({
                        action: 'Impression',
                        productsPayload: {
                            products,
                            mobileBreakpoint: 600,
                        },
                        salesforceResponse: data?.buyAgainRecs,
                        page: { type: 'home' },
                    }));
                }
                return (
                    <>
                        {renderCarousel(titleSF, productRecs)}
                        {renderCarousel(buy_again_title || 'Buy It Again', products?.length >= min_products_to_show ? products : [])}
                    </>
                );

            case 'CUST_PRODUCTS_ONLY':
                trackImpression();
                if (products?.length >= min_products_to_show) {
                    dispatch(emitProductImpression({
                        action: 'Impression',
                        productsPayload: {
                            products,
                            mobileBreakpoint: 600,
                        },
                        salesforceResponse: data?.buyAgainRecs,
                        page: { type: 'home' },
                    }));
                }
                return renderCarousel(buy_again_title || 'Buy It Again', products?.length >= min_products_to_show ? products : []);

            case 'PRODUCT_RECS_ONLY':
                trackImpression();
                return renderCarousel(titleSF, productRecs);

            default:
                return null;
        }
    }
    return null;
};

BuyItAgainRecsSFContainer.propTypes = {
    blockObj: shape({
        title_font_color: shape({
            color: string.isRequired,
        }),
        title_font_size: string,
        title_font_style: string,
        title_font_family: string,
        padding: string,
    }).isRequired,
};

export default BuyItAgainRecsSFContainer;
